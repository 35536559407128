import client from "./client";

const getPlaceBySlug = (slug) => {
  return client
    .get(`/place/is/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getPlaces = (filter) => {
  return client
    .get(`/places`, filter)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const createPlace = (data) => {
  return client
    .post("/place", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

const deletePlaces = (data) => {
  return client
    .put(`/delete/place`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const updatePlace = (data) => {
  return client
    .put(`/place`, data)
    .then((response) => {
      return response;
    }).catch((error) => {
      return Promise.reject(error.response.data);
    });
};

const placeAPI = {
  createPlace,
  updatePlace,
  deletePlaces,
  getPlaces,
  getPlaceBySlug,
};

export default placeAPI;
