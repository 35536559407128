<template>

	<div>

		<!-- Places List header -->
		<a-row type="flex" :gutter="24">
			<a-col :span="12" class="mb-24">
				<a-button type="primary" @click="$router.push('/places/create')">New Place</a-button>
			</a-col>
		</a-row>
		<!-- / Places List header -->

		<!-- Places List card -->
		<a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">
			<!-- Table search -->
			<div class="mx-25">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" class="text-right">
						<a-button v-if="selectedRowKeys.length > 0" type="danger" @click="deleteSelectedRows()"
							style="margin-right: 10px;">
							<a-icon type="delete" />
						</a-button>
						<a-input-search placeholder="input search text" style="max-width: 200px;" v-model="query"
							@input="debouncedSearch" />
					</a-col>
				</a-row>
			</div>
			<!-- / Table search -->

			<!-- Places table -->
			<a-table class="mt-20" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
				:columns="columns" :data-source="places" rowKey="id"
				:pagination="{ pageSize: pageSize, current: currentPage, total: totalCount, onChange: handlePageChange }"
				:loading="isNotLoading">
				<template slot="action" slot-scope="action">
					<a-button type="primary" @click="$router.push(`/places/edit/${action}`)">
						<a-icon type="edit" />
					</a-button>
				</template>
				<!-- Active Column -->
				<template slot="is_active" slot-scope="is_active">
					<a-button v-if="is_active" shape="circle" size="small" class="btn-status border-success mr-5">
						<a-icon class="m-0 text-success" type="check" style="font-size: 10px;" />
					</a-button>
					<a-button v-else shape="circle" size="small" class="btn-status border-danger mr-5">
						<a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />
					</a-button>
					<span style="vertical-align: middle;">{{ is_active ? 'Yes' : 'No' }}</span>
				</template>
			</a-table>
			<!-- / Places table -->

		</a-card>
		<!-- / Places List card -->

	</div>

</template>

<script>
import debounce from 'lodash/debounce';
import placeAPI from '../../api/place';
// Table columns
const columns = [
	{
		title: 'TITLE',
		dataIndex: 'title',
		sorter: (a, b) => a.title > b.title ? 1 : -1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'title' },
	},
	{
		title: 'SUBTITLE',
		dataIndex: 'sub_title',
		sorter: (a, b) => a.sub_title > b.sub_title ? 1 : -1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'sub_title' },
	},
	{
		title: 'ACTION',
		dataIndex: 'slug',
		scopedSlots: { customRender: 'action' },
	},
	{
		title: 'STATUS',
		dataIndex: 'is_active',
		sorter: (a, b) => a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'is_active' },
	},
];

export default {
	data() {
		return {

			// Table columns
			columns,

			// Table Authors
			places: [],

			// Table page size
			pageSize: 10,

			// Table page skip
			currentPage: 1,

			totalCount: 0,

			// Table search query
			query: '',

			// Table's selected rows
			selectedRowKeys: [],

			isNotLoading: false,

			debouncedSearch: () => { },

		}
	},
	computed: {

		// CSV data array
		csvData() {
			return this.authors.map(item => ({
				"Id": "%23" + item.key,
				"Date": item.date,
				"Status": item.status,
				"Customer": item.customer.name,
				"Product": item.product,
				"Revenue": "$" + item.revenue,
			}));
		}

	},
	methods: {
		async deleteSelectedRows() {
			await placeAPI.deletePlaces({
				"selected_row_keys": this.selectedRowKeys,
			}).then(() => {
				if ((this.selectedRowKeys.length === this.places.length || this.places.count === this.selectedRowKeys.length) && this.currentPage > 1) {
					this.handlePageChange(this.currentPage - 1);
				} else {
					this.handlePageChange(this.currentPage);
				}
				this.selectedRowKeys = [];
			});
		},
		async handlePageChange(page) {
			this.currentPage = page;
			const data = await this.getPlaces({
				params: {
					"limit": this.pageSize,
					"skip": (this.currentPage - 1) * this.pageSize,
				}
			})
			this.places = data.places;
			this.totalCount = data.count;
			this.isNotLoading = false;
		},

		// get authors according to filters
		async getPlaces(filter) {
			this.isNotLoading = true;
			return placeAPI.getPlaces(filter);
		},

		// Event listener for input change on table search field.
		async onSearchChange() {
			if (this.query.length >= 3) {
				const data = await this.getPlaces({
					params: {
						"limit": this.pageSize,
						"skip": 0,
						"query": this.query,
					}
				})
				this.places = data.places;
				if (Object.keys(data).length !== 0) {
					this.totalCount = data.count;
				} else {
					this.totalCount = 0;
				}
				this.isNotLoading = false;
			} else {
				const originalData = await this.getPlaces({
					params: {
						"limit": this.pageSize,
						"skip": 0,
					}
				});
				this.places = originalData.places;
				if (Object.keys(originalData).length !== 0) {
					this.totalCount = originalData.count;
				} else {
					this.totalCount = 0;
				}
				this.isNotLoading = false;
			}
		},

		// Event listener for table row selection change.
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},

		// Export table in CSV format.
		csvExport(arrData) {
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += [
				Object.keys(arrData[0]).join("|"),
				...arrData.map(item => Object.values(item).join("|"))
			]
				.join("\n")
				.replace(/(^\[)|(\]$)/gm, "");

			const data = encodeURI(csvContent);
			const link = document.createElement("a");
			link.setAttribute("href", data);
			link.setAttribute("download", "muse-dashboard-csv.csv");
			link.click();
		}
	},
	async mounted() {
		this.debouncedSearch = debounce(this.onSearchChange, 300);
		const data = await this.getPlaces({
			params: {
				"limit": this.pageSize,
				"skip": (this.currentPage - 1) * this.pageSize,
			}
		})
		if (Object.keys(data).length !== 0) {
			this.places = data.places;
			this.totalCount = data.places.length > 0 ? data.count : 0;
		} else {
			this.totalCount = 0;
		}
		this.isNotLoading = false;
	},
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
	display: flex;
	align-items: center;
}

.table-avatar-info .ant-avatar {
	margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
	font-size: 12px;
}

.btn-status::v-deep .anticon {
	line-height: 0;
}
</style>